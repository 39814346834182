import { template as template_88da602f1d744a92ac22a78d8c5fe893 } from "@ember/template-compiler";
const FKText = template_88da602f1d744a92ac22a78d8c5fe893(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
