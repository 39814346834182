import { template as template_0a55d83008c0436a92e1d388652b785b } from "@ember/template-compiler";
const FKLabel = template_0a55d83008c0436a92e1d388652b785b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
