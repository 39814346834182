import { template as template_148d3ec8981f42b5a70efd4afdf4047d } from "@ember/template-compiler";
const FKControlMenuContainer = template_148d3ec8981f42b5a70efd4afdf4047d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
